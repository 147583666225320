import { defineStore } from 'pinia'
import ConfigSap from '../services/config-sap.service'
import { useOrdenesStore } from './ordenes.store'
import dayjs from 'dayjs'
import { useStore } from '@/store'
const _ConfigSap = new ConfigSap()
const ordenesStore = useOrdenesStore()
const storePersona = useStore()
export const useConfigSap = defineStore('configSapStore', {
  state: () => ({
    configuraciones_ov: {},
    fecha_minima_contabilizacion: '',
    dias_minimos_entrega_mipres_general: 0,
    dias_maximo_entrega_mipres_general: 0,
    configuracion_encontrada_usuario: {
      id: 0,
      permiso: '',
      usuario: 0,
      cantidad_dias: 0,
      fecha_inicio: '',
      fecha_fin: ''
    }
  }),
  getters: {
    _configuraciones_ov: state => state.configuraciones_ov,
    _fecha_minima_contabilizacion: state => state.fecha_minima_contabilizacion,
    _dias_minimos_entrega_mipres_general: state => state.dias_minimos_entrega_mipres_general,
    _dias_maximo_entrega_mipres_general: state => state.dias_maximo_entrega_mipres_general,
    _configuracion_encontrada_usuario: state => state.configuracion_encontrada_usuario
  },
  actions: {
    obtenerListadoConfiguraciones () {
      _ConfigSap.get().then(({ data }) => {
        this.configuraciones_ov = data
        this.dias_minimos_entrega_mipres_general = +data.difference_of_minium_days_to_create_mipres_ov.Value
        this.dias_maximo_entrega_mipres_general = +data.difference_of_maximum_days_to_generate_OV_when_it_is_mipres.Value
        this.validacionFechas()
      })
    },
    validacionFechas () {
      if (ordenesStore._udf.mot_autoriza.Codigo === '05' || ordenesStore._udf.mot_autoriza.Codigo === '06') {
        this.findConfigUsuario(storePersona.getters['auth/user'].user_id).then((data) => {
          if (data && dayjs().isBefore(data.fecha_fin)) {
            this.fecha_minima_contabilizacion = dayjs().subtract(data.cantidad_dias, 'days').format('YYYY-MM-DD')
          } else {
            this.fecha_minima_contabilizacion = dayjs().subtract(+this._configuraciones_ov.difference_of_minium_days_to_create_mipres_ov.Value, 'days').format('YYYY-MM-DD')
          }
        })
      } else {
        this.fecha_minima_contabilizacion = dayjs().subtract(+this._configuraciones_ov.days_previous_docduedate.Value, 'days').format('YYYY-MM-DD')
      }
    },
    crearConfigSapMipresUsuario (payload) {
      return _ConfigSap.crearPermisoTemporalMipres(payload).then(({ data }) => {
        return data
      })
    },
    findConfigUsuario (params) {
      return _ConfigSap.findConfigByUser(params).then(({ data }) => {
        this.configuracion_encontrada_usuario = data || {
          id: 0,
          permiso: '',
          usuario: 0,
          cantidad_dias: 0,
          fecha_inicio: '',
          fecha_fin: ''
        }
        return data
      })
    },
    updateConfigUsuario (id, payload) {
      return _ConfigSap.actualizarConfigUserMipres(id, payload).then(({ data }) => {
        return data
      })
    },
    updataGeneralConfig (payload) {
      return _ConfigSap.update(payload).then(({ data }) => {
        return data
      })
    }
  }
})
